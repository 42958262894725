import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

//Inject the service in each component you want to use your svg

@Injectable({
  providedIn: 'root'
})
export class IconService {

  constructor( private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
      this.matIconRegistry.addSvgIcon(
        'circle-check-mark',
        this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/Svg/Circle Check Mark.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'right-arrow',
        this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/Svg/Right Arrow.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'x-mark',
        this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/Svg/X-Mark.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'back-arrow',
        this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/Svg/Back Arrow.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'user',
        this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/Svg/User.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'search',
        this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/Svg/Search.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'settings',
        this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/Svg/Settings.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'contact',
        this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/Svg/Contact.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'home',
        this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/Svg/Home.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'infos',
        this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/Svg/Infos.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'www',
        this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/Svg/Attachments/Www.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'pdf',
        this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/Svg/Attachments/Pdf.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'link',
        this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/Svg/External Link.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'heart',
        this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/Svg/Heart.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'heart-solid',
        this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/Svg/Heart Solid.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'trash',
        this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/Svg/Trash.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'star',
        this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/Svg/Star.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'star-solid',
        this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/Svg/Star Solid.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'plus',
        this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/Svg/Plus.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'tabs',
        this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/Svg/Tabs.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'contact-label',
        this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/Svg/Contact Label.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'bell',
        this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/Svg/Bell.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'location',
        this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/Svg/Location.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'phone',
        this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/Svg/Phone.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'mail',
        this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/Svg/Mail.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'accessibility',
        this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/Svg/Accessibility.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'shortcut',
        this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/Svg/Shortcut.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'information',
        this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/Svg/Information.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'accessibility',
        this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/Svg/Accessibility.svg')
      );
      this.matIconRegistry.addSvgIcon(
        'minus',
        this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/Svg/Minus.svg')
      );
    }
}
