export const environment = {
  production: false,
  //authenticatedBaseUrl: "http://localhost:5000/api/Authorization",
  //anonymousBaseUrl: "http://localhost:5000/api/Anonymous",
  authenticatedBaseUrl: "https://api.spicetech.solutions/QVT/api/Authorization",
  anonymousBaseUrl: "https://api.spicetech.solutions/QVT/api/Anonymous",
  oneSignal: {
    appId: 'd587de79-1d36-4196-8b20-46352c4dae23'
  }
};
