import { Component, OnInit } from '@angular/core';

//Services
import { IconService } from './Shared/Services/SVG/icon.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit{

  constructor(private iconService: IconService) {
  }

  ngOnInit() {
  }


  public static scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

}
