import { MainService } from 'src/app/Shared/Services/MAIN SERVICE/main.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router,
              private mainService: MainService) {
  }

  canActivate(): boolean {
    try{
      const token = this.mainService.getFromStorage('_response').accessToken;
      if (token) {
        const decodedToken: any = jwt_decode(token);
        const expirationTime = decodedToken.exp;
        const currentTime = Date.now() / 1000;
        if (expirationTime < currentTime) {
          this.router.navigate(['/authentication']);
          return false;
        }
        return true;
      } else {
        this.router.navigate(['/authentication']);
        return false;
      }
    }catch(err){
      this.router.navigate(['/authentication']);
      return false;
    }

  }

}
