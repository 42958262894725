import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

//Services
import { OneSignalService } from '../ONE SIGNAL/one-signal.service';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  constructor(private router: Router,
              private location: Location,
              private oneSignalService: OneSignalService) {
    this.initBeforeInstallPrompt();
  }

  private initBeforeInstallPrompt() {
    window.addEventListener('beforeinstallprompt', (event: any) => {
      event.preventDefault();
      this.deferredPrompt = event;
    });
  }

  //Others
  public isFirstTimeAccess() {
    try {
      let isFirstTime = this.getFromStorage('_response').firstTime;

      if (isFirstTime) {
        return true;
      }

      return false;

    } catch (err) {
      this.router.navigate(['/authentication']);
      return null;
    }
  }

  public async isAppLaunched(){
    return this.getFromStorage('isAppLaunched');
  }

  //Localstorage
  public setToStorage(key: string, value: any) {
    localStorage.setItem(key, typeof (value) == 'string' ? value : JSON.stringify(value));
  }
  public getFromStorage(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }
  public deleteFromStorage(key: string) {
    localStorage.removeItem(key);
  }
  public deleteAllFromStorage() {
    let isLaunched = this.getFromStorage('isAppLaunched');
    localStorage.clear();
    if (isLaunched) {
      this.setToStorage('isAppLaunched', isLaunched);
    }
  }

  //App Installation (pwa)
  private deferredPrompt: any;
  public install() {
    if (this.deferredPrompt) {
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice.then((userChoice) => {
        this.deferredPrompt = null;
        if (userChoice.outcome === 'accepted') {
          if (userChoice.installPrompt) {
            this.router.navigate(['/preparation']);
          }
        }
      })
        .catch(err => {
          throw new Error(err.message);
        });
    }
  }

  //Authentication
  public async isLoggedIn() {
    let response = this.getFromStorage('_response');
    if (response) {
      return true;
    }
    return false;
  }
  public logout() {
    this.deleteAllFromStorage();
    this.unsubscribeToPushNotification();
    this.location.replaceState('/authentication/login');
    this.router.navigate(['/authentication/login'], { state: { refresh: true } });
  }

  //push notification
  public subscribeToPushNotification() {
    this.oneSignalService.onInit().then(() => {
      this.oneSignalService.getPlayerId().then((playerId) => {
        this.oneSignalService.subscribe(playerId);
        this.setToStorage('player_Id', playerId);
        let accountId = this.getFromStorage('_response').id;
        this.oneSignalService.setTags({ account_id: accountId });
      })
    })
  }
  public unsubscribeToPushNotification() {
    this.oneSignalService.unsubscribe();
  }
}
