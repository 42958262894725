import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, CanActivate } from '@angular/router';
import { AuthGuard } from './Shared/Guards/Auth/auth.guard';

const routes: Routes = [
  {path: '', redirectTo: 'intro', pathMatch: 'full'},
  {
    path: 'intro',
    title: 'QVT - Introduction',
    loadChildren: () => import('./Pages/Introducation/intro/intro.module').then(m => m.IntroPageModule)
  },
  {
    path: 'confidentialite',
    title: 'QVT - Confidentialité',
    loadChildren: () => import('./Pages/Introducation/confidentialite/confidentialite.module').then( m => m.ConfidentialitePageModule)
  },
  {
    path: 'loader',
    title: 'QVT - Chargement...',
    loadChildren: () => import('./Pages/Loaders/loader/loader.module').then( m => m.LoaderPageModule)
  },
  {
    path: 'connaissance',
    title: 'QVT - Connaissance',
    canActivate: [AuthGuard],
    loadChildren: () => import('./Pages/Startup Pages/connaissance/connaissance.module').then( m => m.ConnaissancePageModule)
  },
  {
    path: 'stepper',
    canActivate: [AuthGuard],
    loadChildren: () => import('./Pages/Startup Pages/stepper/stepper.module').then( m => m.StepperPageModule)
  },
  {
    path: 'preparation',
    title: 'QVT - Preparation...',
    canActivate: [AuthGuard],
    loadChildren: () => import('./Pages/Loaders/preparation/preparation.module').then( m => m.PreparationPageModule)
  },
  {
    path: 'application',
    canActivate: [AuthGuard],
    loadChildren: () => import('./Pages/Application/application.module').then( m => m.ApplicationPageModule)
  },
  {
    path: 'authentication',
    loadChildren: () => import('./Pages/Authentication/authentication.module').then( m => m.AuthenticationPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
