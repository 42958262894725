import { Injectable } from '@angular/core';
import { OneSignal } from 'onesignal-ngx';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OneSignalService {

  public appId: string = environment.oneSignal.appId;

  constructor(private oneSignal: OneSignal) { }

  async onInit() {
    await this.oneSignal.init({
      appId: this.appId});
  }

  async getPlayerId() {
    var playerId = await this.oneSignal.getUserId();
    return playerId;
  }

  async setTags(tags: any) {
    await this.oneSignal.sendTags(tags);
  }

  async unsubscribe() {
    await this.oneSignal.setSubscription(false);
    await this.oneSignal.deleteTag('account_id');
    await this.oneSignal.removeExternalUserId();
  }

  async subscribe(playerId: string) {
    this.oneSignal.getNotificationPermission().then(async (permission: any) => {
      if(permission == 'default'){
        localStorage.removeItem('onesignal-notification-prompt');
        await this.oneSignal.showSlidedownPrompt();
      }else{
        await this.oneSignal.setSubscription(true);
        await this.oneSignal.setExternalUserId(playerId);
      }
    });

  }

}
